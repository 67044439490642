import { Box, CircularProgress, ThemeProvider } from '@mui/material'
import { KeyCloakProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

const HomeWithChallengesProvider = lazy(() =>
    import('../pages').then((module) => ({
        default: module.HomeWithChallengesProvider
    }))
)

const ChallengeWithChallengesProvider = lazy(() =>
    import('../pages').then((module) => ({
        default: module.ChallengeWithChallengesProvider
    }))
)

export enum ROUTES {
    HOME = '/challenges',
    CHALLENGE = '/challenges/:challengeId'
}

const Router: React.FC = () => {
    const theme = useTheme()

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ top: 0 }}>
                <BrowserRouter>
                    <Suspense fallback={<CircularProgress />}>
                        <Switch>
                            <KeyCloakProtectedRoute
                                path={ROUTES.CHALLENGE}
                                component={ChallengeWithChallengesProvider}
                            />
                            <KeyCloakProtectedRoute
                                path={ROUTES.HOME}
                                component={HomeWithChallengesProvider}
                            />
                        </Switch>
                    </Suspense>
                </BrowserRouter>
            </Box>
        </ThemeProvider>
    )
}

export default Router
